import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { type MRT_Row } from 'material-react-table';

import { cancelPendingTransaction } from '../../../services/econsent/econsentServices';
import { AxiosError } from 'axios';
import { ShowErrorAlert, ShowSuccessAlert } from '../../../utilities/sweetalert';

import { Button, Box, CircularProgress } from '@mui/material';
import { Modal } from '@mui/material';
import ConfirmCancelModalForm from './form';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface Props {
    open: boolean;
    handleClose: () => void;
    row: MRT_Row;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const ConfirmCancelModal = ({
    open,
    handleClose,
    row,
    isLoading,
    setIsLoading
}: Props) => {
    const [reason, setReason] = useState<string>('')

    const handleConfirm = async () => {
      setIsLoading(true);
      try {
        const data = await cancelPendingTransaction({ transactionId: row.renderValue<string>("transactionId"), reason });
        await ShowSuccessAlert({ text: "Your request has been sent." })
        handleClose()
      }
      catch (err) {
        setIsLoading(false);
        if (err instanceof AxiosError) {
          ShowErrorAlert({
            text: err.response?.data
          })
        }
      }
    }

    const handleChange = useCallback((value: string) => {
        setReason(value)
    }, [])
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <p><b>{row.renderValue<string>("fullName")} : {row.renderValue<string>("hn")}</b></p>
                    <p>Are you sure you want to cancel this transaction? If so, please provide a reason below. Click 'Confirm' to proceed or 'Close' to go back.</p>
                    { open && <ConfirmCancelModalForm onChange={handleChange} value={reason} /> }
                </div>
                <br />
                <div style={{ display: "flex" }}>
                    <Button variant='contained' onClick={handleConfirm} disabled={isLoading || !reason}>
                        {isLoading && <CircularProgress color='inherit' sx={{ marginRight: 3 }} size={24} />}
                        Confirm
                    </Button>
                    <Button variant='contained' color='error' sx={{ marginLeft: 2 }} onClick={handleClose}>Close</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default ConfirmCancelModal