import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

export const ShowErrorAlert = <T>({ title, icon, text, ...options }: SweetAlertOptions): Promise<SweetAlertResult<T>> => {
    return ShowAlert({
        title: "Oops!!!",
        icon: "error",
        text: text || "Something went wrong.",
        ...options
    })
}

export const ShowSuccessAlert = <T>({ title, icon, text, ...options }: SweetAlertOptions): Promise<SweetAlertResult<T>> => {
    return ShowAlert({
        title: title || "Success",
        text: text || "Success",
        icon: icon || "success",
        ...options
    })
}

export const ShowAlert = async <T>(options: SweetAlertOptions): Promise<SweetAlertResult<T>> => {
    const result = await Swal.fire(options)
    return result
}