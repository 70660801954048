import React from 'react'
import { Button, ButtonProps, Stack } from '@mui/material'
import { channelType as channelTypeDtos } from '../../services/econsent/types';
import { ChannelType, mapToChannelTypeDtos } from './types'

const colors = {
    [ChannelType.ALL]: 'primary',
    [ChannelType.SALEFORCE]: 'secondary',
    [ChannelType.IPS]: 'success',
    [ChannelType.TRAKCARE]: 'warning'
} as Record<ChannelType, ButtonProps['color']>

type Props = {
    isLoading: boolean;
    onToggleChannel: (ChannelType: channelTypeDtos) => void;
    channel: channelTypeDtos
}

const ChannelFilter = ({
    isLoading,
    onToggleChannel,
    channel
}: Props) => {
    return (
        <Stack direction={'row'} spacing={1} mb={1}>
            {
                Object.entries(ChannelType)
                    .filter(([_, label]) => label != ChannelType.NONE)
                    .map(([key, label]) => {
                        const dtos = mapToChannelTypeDtos(label)

                        return (
                            <Button
                                key={key}
                                // color={colors[label]}
                                color={'primary'}
                                onClick={() => onToggleChannel(dtos)}
                                sx={{ flex: 1 }}
                                variant={channel === dtos ? "outlined" : "contained"}
                                disabled={isLoading}
                            >
                                {label}
                            </Button>
                        )
                    })
            }
        </Stack>
    )
}

export default ChannelFilter