import React, { useState } from "react";
import { IIdleTimer, useIdleTimer } from "react-idle-timer";

type Props = {
  onIdle: () => void;
  onPrompt: () => void;
  idleTime: number;
  promptTime: number;
}

function useIdle({
  onIdle,
  onPrompt,
  idleTime,
  promptTime
}: Props) {
  const [isIdle, setIsIdle] = useState<boolean>(false);

  const handleOnIdle = (event?: Event, idleTimer?: IIdleTimer) => {
    setIsIdle(true);
    onIdle();
  };

  const handleOnPrompt = (event?: Event, idleTimer?: IIdleTimer) => {
    onPrompt()
  }

  const { getRemainingTime, getLastActiveTime, reset, isPrompted } = useIdleTimer({
    timeout: 1000 * idleTime,
    onIdle: handleOnIdle,
    promptBeforeIdle: 1000 * promptTime,
    onPrompt: handleOnPrompt,
    debounce: 500,
  });

  const handleOnResetIdle = () => {
    setIsIdle(false);
    reset()
  }

  return {
    getRemainingTime,
    getLastActiveTime,
    reset: handleOnResetIdle,
    isIdle,
    isPrompted
  };
}

export default useIdle;