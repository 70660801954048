import { CancellationReasonMaster } from "../../services/econsent/types";
import { getCancellationReasons } from "./master.action";

export interface ContextValue {
    state: State;
    dispatch: (callback: (state: State) => void) => void;
}

export interface State {
    loading: boolean;
    cancellationReason: CancellationReasonMaster[];
}

export interface Action {
    getCancellationReasons: ReturnType<typeof getCancellationReasons>
}

export type ActionPayload =
    | { type: 'GET_CANCELLATION_REASON_SUCCESS'; payload: CancellationReasonMaster[] }

export enum ActionType {
    GET_CANCELLATION_REASON_SUCCESS = 'GET_CANCELLATION_REASON_SUCCESS'
}