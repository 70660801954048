import React, { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { MasterContextValue } from '../../../contexts/type';
import { MasterContext } from '../../../contexts';
import { Autocomplete, AutocompleteRenderInputParams, Box, FormControl, FormHelperText, InputLabel, Stack, TextField, Typography } from '@mui/material';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

type Value = {
  value: string;
  label: string;
  description: string;
  requestDetail: boolean;
}

const ConfirmCancelModalForm = ({
  onChange,
  value: defaultValue
}: Props) => {
  // Prepare options for autocomplete
  const { state: { cancellationReason } } = useContext<MasterContextValue>(MasterContext);
  const cancellationReasonOptions = useMemo(() => {
    return cancellationReason.map(item => ({ value: item.reason, label: item.reason, description: item.description, requestDetail: item.requestDetail })) as Value[]
  }, [cancellationReason]);

  // State and handle
  const [description, setDescription] = useState<string>();
  const [detail, setDetail] = useState<string>();

  const handleChange = useCallback((event: SyntheticEvent<Element, Event>, newValue: Value | null) => {
    setDetail("")
    onChange(newValue?.value ?? "")
    setDescription(newValue?.description ?? "")
  }, [onChange]);

  // default value
  const value = useMemo(() => cancellationReasonOptions.find(it => defaultValue.startsWith(it.value)) ?? null, [defaultValue]);

  const handleChangeDetail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let detail = event.target.value
    setDetail(detail);
    
    onChange(`${value?.value} - ${detail}`)
  }, [value])

  return (
    <Stack mt={2} spacing={2}>
      <FormControl fullWidth>
        <Autocomplete
          renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
            <TextField {...params} label="Cancellation Reason" />
          )}
          options={cancellationReasonOptions}
          renderOption={(props, option) => (
            <li {...props}>
              <Stack>
                <Typography>{option.label}</Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {option.description}
                </Typography>
              </Stack>
            </li>
          )}
          value={value}
          onChange={handleChange}
        />
        {description && <FormHelperText>{description}</FormHelperText>}
      </FormControl>
      {
        value?.requestDetail && (
          <TextField
            label='Reason'
            fullWidth
            multiline
            rows={3}
            value={detail}
            onChange={handleChangeDetail}
          />
        )
      }
    </Stack>
  )
}

export default ConfirmCancelModalForm