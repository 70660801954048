import React, { Suspense } from 'react'
import Appbar from './Appbar'
import { Outlet } from "react-router-dom"
import { RemainingTimer } from '../Shared'

const Layout = () => {
    return (
        <>
            <Appbar />
            <br />
            <RemainingTimer />
			<Suspense fallback={'loading...'}>
				<Outlet />
			</Suspense>
        </>
    )
}

export default Layout