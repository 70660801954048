import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { Layout } from "../components/Layouts";

const DashboardPage = lazy(() => import("../pages/dashboard/dashboard.page"));

export default function privateRoutes() {
  return {
    element: <Layout />,
    children: [
      { path: "/", element: <DashboardPage /> },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  };
}