import { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import { User } from '../../services/signin/types';
import { Button } from '@mui/material';
import { ShowAlert } from '../../utilities/sweetalert';
import { logout } from '../../utilities/authentication';

export const handleLogout = () => {
  ShowAlert({
    title: 'Are you sure?',
    text: "Do you want to log out!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.isConfirmed) {
      ShowAlert({
        title: 'LOG OUT!',
        text: 'You have been logged out.',
        icon: 'success',
        showCancelButton: false,
        timer: 2000
      })
      logout()
    }
  })
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  }
}));

const Appbar = () => {
  const classes = useStyles();
  const [user, setUser] = useState<User>(() => JSON.parse(sessionStorage.getItem('user') || ''));
  
  return (
    <AppBar position="static">
      <Toolbar>
        <div>
          <IconButton color="inherit">
            <Avatar src={user.avatar} />
          </IconButton>
        </div>
        <Typography variant="h6" className={classes.title}>
          {user.name}
        </Typography>
        <div style={{ paddingLeft: 2 + 'em' }}>
          <Button className={classes.menuButton} variant='contained' color="info" onClick={handleLogout}>Logout</Button>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Appbar