import { Session } from "../../services/signin";
import { setSessionExpiryConfig } from "./config.action";

export interface ContextValue {
    state: State;
    dispatch: (callback: (state: State, dispatch?: React.Dispatch<ActionPayload>) => Promise<void> | void) => void;
}

export interface State {
    expiryInSecond: number;
    promptExpiryInSecond: number;
}

export interface Action {
    setSessionExpiryConfig: ReturnType<typeof setSessionExpiryConfig>
}

export type ActionPayload =
    | { type: ActionType.SET_SESSION_EXPIRY_CONFIG; payload: Session }

export enum ActionType {
    SET_SESSION_EXPIRY_CONFIG = 'SET_SESSION_EXPIRY_CONFIG'
}