import React, { PropsWithChildren, useCallback, useReducer } from 'react'
import ConfigContext, { initialState } from './config.context';
import { ActionPayload, ActionType, State } from './type';

interface Props {

}

export const ConfigContextProvider: React.FC<PropsWithChildren<Props>> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleDispatch = useCallback((callback: (state: State, dispatch: React.Dispatch<ActionPayload>) => void) => {
        callback?.(state, dispatch)
    }, [state, dispatch])

    return (
        <ConfigContext.Provider value={{ state, dispatch: handleDispatch }}>
            {children}
        </ConfigContext.Provider>
    );
};

function reducer(state: State, action: ActionPayload): State {
    switch (action.type) {
        case ActionType.SET_SESSION_EXPIRY_CONFIG:
            const { expiryInSecond = initialState.expiryInSecond, promptExpiryInSecond = initialState.promptExpiryInSecond } = action.payload ?? {}
            return {
                ...state,
                expiryInSecond,
                promptExpiryInSecond
            };
        default:
            return state;
    }
}