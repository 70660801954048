import { ConfigOptions, download, generateCsv, mkConfig } from "export-to-csv";
import { MRT_Row } from "material-react-table";

export const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
});

export const exportNDownloadFileCsv = <T extends {}>(rows: T[], config: ConfigOptions | null = null) => {
    const csv = generateCsv(config ?? csvConfig)(rows);
    download(csvConfig)(csv);
};