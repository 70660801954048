import { useContext, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import { CancelScheduleSend } from '@mui/icons-material';
import { type MRT_Row } from 'material-react-table';
import LoadingOverlay from 'react-loading-overlay-ts';

import { statusType } from '../../../pages/dashboard/types';
import { MasterContext } from '../../../contexts';
import { MasterContextValue } from '../../../contexts/type';
import { getCancellationReasons } from '../../../contexts/master/master.action';
import ConfirmCancelModal from './modal'

export default function ConfirmCancelModalAction(row: MRT_Row) {
  const { dispatch } = useContext<MasterContextValue>(MasterContext);

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOpen = async () => {
    dispatch(getCancellationReasons())
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
  }

  const isEnabled = [statusType.PENDING_TO_CREATE_TRANSACTION, statusType.PENDING_PATIENT, statusType.PENDING_WITNESS].includes(row.renderValue<string>("status"))

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading...'
      styles={{
        spinner: (styles) => {
          return {
            ...styles,
            marginLeft: 0
          }
        }
      }}
    >
      <div>
        <Box sx={{ display: 'inline-block', gap: '1rem' }}>
          <Tooltip arrow placement="right" title="Cancel">
            <IconButton disabled={!isEnabled}
              onClick={handleOpen}>
              <CancelScheduleSend />
            </IconButton>
          </Tooltip>
        </Box>
        <ConfirmCancelModal
          open={open}
          handleClose={handleClose}
          row={row}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setOpen={setOpen}
        />
      </div>
    </LoadingOverlay>
  );
}