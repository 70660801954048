import React, { useContext, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay-ts';

import { loginUser } from '../../services/signin/index';
import { Session, User } from '../../services/signin/types';
import { Container, Stack } from '@mui/material';
import { ConfigContextValue } from '../../contexts/type';
import { ConfigContext } from '../../contexts';
import { setSessionExpiryConfig } from '../../contexts/config/config.action';
import { ShowSuccessAlert } from '../../utilities/sweetalert';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://www.bumrungrad.com/assets/img/logo.svg)',
    backgroundSize: 'auto 10%'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Signin() {
  const { dispatch } = useContext<ConfigContextValue>(ConfigContext);
  const classes = useStyles();
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const response = await loginUser(
      username,
      password
    );
    if (Boolean(response.session_id)) {
      ShowSuccessAlert({
        text: response.message,
        showConfirmButton: false,
        timer: 1500,
      })
        .then(() => {
          dispatch(setSessionExpiryConfig({ expiryInSecond: response.expiryInSecond, promptExpiryInSecond: response.promptExpiryInSecond }))

          const user: User = {
            id: response.employeeNumber,
            name: response.firstName + " " + response.lastName,
            avatar: "https://www.bumrungrad.com/getattachment/298631FD-6FCF-4456-BF04-D6800F0B7B3A/image.jpg",
            permissions: response.permissions.map((permission) => permission.name),
          };
          sessionStorage.setItem('accessToken', response.token);
          sessionStorage.setItem('user', JSON.stringify(user));
          window.location.href = "/";
        });
    }
    else {
      Swal.fire("Failed", response.message, "error");
    }

    setLoading(false);
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Loading...'
    >
      <Stack className={classes.root} direction={'row'} justifyContent={'center'} alignItems={'center'}>
        <Container maxWidth="sm">
          <Grid container justifyContent="center">
            <CssBaseline />
            <Grid item xs={12} component={Paper} elevation={6} square>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    name="username"
                    label="User Name"
                    onChange={e => setUserName(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign In
                  </Button>
                </form>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </LoadingOverlay>
  );
}