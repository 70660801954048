import { getCancellationReasonsMaster } from "../../services/econsent/econsentServices";
import { ActionPayload, ActionType, State } from "./type";

export const getCancellationReasons = () => async (state: State, dispatch?: React.Dispatch<ActionPayload>) => {
    if (state.cancellationReason?.length) return;

    try {
        const response = await getCancellationReasonsMaster();
        dispatch?.({ type: ActionType.GET_CANCELLATION_REASON_SUCCESS, payload: response });
    } catch (error: any) {
        console.error(error);
    }
}