import { Button, ButtonProps, Stack } from '@mui/material';
import { activeTypes } from './types';

const labels = {
    [activeTypes[1]]: 'PENDING PATIENT',
    [activeTypes[2]]: 'PENDING WITNESS',
    [activeTypes[3]]: 'COMPLETED',
    [activeTypes[4]]: 'CANCELLED',
    [activeTypes[5]]: 'ALL STATUS',
}

const colors = {
    [activeTypes[1]]: 'warning',
    [activeTypes[2]]: 'info',
    [activeTypes[3]]: 'success',
    [activeTypes[4]]: 'error',
    [activeTypes[5]]: 'secondary',
} as Record<string, ButtonProps['color']>

type Props = {
    isLoading: boolean;
    onToggleStatus: (newStatus: typeof activeTypes[number]) => void;
    status: string;
}

const StatusFilter = ({
    isLoading,
    onToggleStatus,
    status
}: Props) => {
    return (
        <Stack direction={'row'} spacing={1} mb={1}>
            {
                activeTypes
                    .slice(1)
                    .map(it => (
                        <Button
                            key={it}
                            color={colors[it]}
                            disabled={isLoading}
                            onClick={() => {
                                onToggleStatus(it);
                            }}
                            variant={status === it ? "outlined" : "contained"}
                        >
                            {labels[it]}
                        </Button>
                    ))
            }
        </Stack>
    )
}

export default StatusFilter