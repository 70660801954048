import axios, { AxiosError } from 'axios';
import { ShowAlert } from '../../utilities/sweetalert';
import { logout } from '../../utilities/authentication';

const econsentAPI = axios.create({
  baseURL: process.env.REACT_APP_ECONSENT_URL,
});

// Request interceptor to add authorization header from sessionStorage
econsentAPI.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('accessToken');
    
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 responses
econsentAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      // Show session timeout alert
      ShowAlert({
        icon: 'error',
        title: 'Session Expired',
        text: 'Your session has timed out. Please sign in again.',
        confirmButtonText: 'Sign In',
        allowOutsideClick: false,
      }).then(logout);
    }
    return Promise.reject(error);
  }
);

export default econsentAPI;