import { channelType as channelTypeDtos } from '../../services/econsent/types'

export const statusType: Record<string, string> = {
  PENDING_TO_CREATE_TRANSACTION: 'PENDING CREATE TRANSACTION',
  PENDING_PATIENT: 'PENDING PATIENT',
  PENDING_WITNESS: 'PENDING WITNESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED'
  // UNDEFINED: 'UNDEFINED'
}

export type statusType = {
  [k in keyof typeof statusType]: k
}

export const columnKeys: { [key: string]: string } = {
  appointmentDateTime: "Appointment Date/Time",
  remainingDays: "Remaining Days",
  hn: "HN",
  fullName: "Patient Name",
  status: "Status",
  templateName: "eConsent Name",
  salesforce_Case_Appointment_Id: "SF Appointment ID",
  email: "Email",
  nationalID: "National ID",
  passportNo: "Passport No",
  birthDate: "Date Of Birth",
  voidedReason: "eConsent Voided Reason",
  patientSubmittedAt: "Submitted Date/Time",
  witnessSubmittedAt: "Witness Signed Date/Time",
  e_Signature_Link: "eSignature Link",
  transactionId: "Transaction Id",
  fullNameTh: "Patient Name (Th)",
  fullNameEn: "Patient Name (En)",
  e_Signature_LinkText: "eSignature Link",
  channel: "Channel",
  createdAt: "Transaction Created Date/Time"
} as const;

export type ColumnKeys = { [K in keyof typeof columnKeys]: K };
export const columnPropertyName: ColumnKeys = Object.freeze(Object.fromEntries(Object.keys(columnKeys).map(it => [it, it])))

export const activeTypes = ['No', 'PendingPatient', 'PendingWitness', 'Completed', 'Cancelled', 'All'];

export enum ChannelType {
  NONE = 'None',
  SALEFORCE = 'Saleforce',
  IPS = 'IPS',
  TRAKCARE = 'Trakcare',
  ALL = 'All Channel'
}

export const displayChannelType = {
  [ChannelType.NONE]: 'None',
  [ChannelType.SALEFORCE]: 'Saleforce Appointment',
  [ChannelType.IPS]: "In-Person Signing",
  [ChannelType.TRAKCARE]: "TrakCare Appointment"
} as Record<string, string>

export const mapToChannelTypeDtos = (channelType: ChannelType): channelTypeDtos => {
  switch (channelType) {
    case ChannelType.SALEFORCE: return channelTypeDtos.SALEFORCE
    case ChannelType.IPS: return channelTypeDtos.IPS
    case ChannelType.TRAKCARE: return channelTypeDtos.TRAKCARE
    default: return channelTypeDtos.ALL
  }
}