import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const Router = () => {
  const isAuthenticated = !!sessionStorage.getItem('accessToken');

  const router = createBrowserRouter([
    isAuthenticated ? PrivateRoute() : {},
    ...PublicRoute(),
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
