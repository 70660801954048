import { signinResponse } from './types';
import { signinAPI } from './signinApi';

export async function loginUser(username: string, password: string): Promise<signinResponse> {
  const res = await signinAPI.post<signinResponse>(
    `${process.env.REACT_APP_SIGNIN_SUFFIX}/users/login`,
    {
      username: username,
      password: password
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_SIGNIN_KEY}`
      }
    }
  )
    .then((res) => res.data)
    .then((data) => {
      const hasEsignature = data.permissions.some((item) => item.name.toLowerCase().includes(process.env.REACT_APP_ESIGNATURE_PERMISSION || 'esignature'));
      if (!hasEsignature) {
        data.session_id = '';
        data.message = 'No permissions found';
      }
      return data;
    })
    .catch((err) => {
      const errorResp: signinResponse = {
        session_id: '',
        token: '',
        message: err.message,
        permissions: [],
        employeeNumber: '',
        firstName: '',
        lastName: '',
        expiryInSecond: 3600,
        promptExpiryInSecond: 30
      }
      return errorResp;
    })
  return res;
}
