import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useState, useEffect, forwardRef, ForwardedRef, useImperativeHandle, useCallback } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export type CustomDatePickerRef = {
  getDate: () => Date
  setDate: (date: Date) => void
}

export const CustomDatePicker = forwardRef((props: any, ref: ForwardedRef<CustomDatePickerRef>) => {
  const [date, setDate] = useState<MaterialUiPickersDate>(null);

  const handleGetDate = useCallback(() => date, [date])
  const handleSetDate = useCallback((date: Date) => setDate(date), [date])

  useImperativeHandle(
    ref,
    () => ({
      getDate: handleGetDate,
      setDate: handleSetDate
    } as CustomDatePickerRef),
    [handleGetDate],
  )

  useEffect(() => {
    props.setFilterValue(date?.setHours(0, 0, 0, 0));
  }, [date]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label={props.label ?? "Date picker"}
        format="dd/MM/yyyy"
        clearable
        value={date}
        disabled={props.disabled}
        disableToolbar={props.disabled}
        onChange={(event) => {
          setDate(event);
        }}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
});