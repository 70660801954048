import { useState } from 'react';
import { Button, Box, Tooltip } from '@mui/material';
import { Modal } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import { ContentPaste } from '@mui/icons-material';
import { type MRT_Row } from 'material-react-table';
import LoadingOverlay from 'react-loading-overlay-ts';

import { downloadPdf } from '../../services/econsent/econsentServices';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PdfModal( row  : MRT_Row) {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [metadata, setMetaData] = useState<string>('');

  const handleOpen = async () => {
    setIsLoading(true);
    try{
      const data = await downloadPdf({ transactionId: row.renderValue<string>("transactionId") });
      console.log(data);
      setIsLoading(false);
      setOpen(true);
      setMetaData(URL.createObjectURL(data));
    }
    catch(err){
      console.log(err);
      setIsLoading(false);
      alert(err);
    }
  }
  const handleClose = () => setOpen(false);
  const handlePDF = () => window.open(metadata, '_blank');

  const isStatusCompleted = row.renderValue<string>("status") === 'COMPLETED'
  
  return (
    <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading...'
        styles={{
            spinner: (styles) => {
                return {
                    ...styles,
                    marginLeft: 0
                }
            }
        }}
    >
    <div>
      <Box sx={{ display: 'inline-block', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Consent">
              <IconButton disabled={!isStatusCompleted}
                onClick={handleOpen}>
                <ContentPaste />
              </IconButton>
            </Tooltip>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p><b>{row.renderValue<string>("fullName")} : {row.renderValue<string>("hn")}</b></p>
            <p>eConsent Name : {row.renderValue<string>("templateName")}</p>
            
          </div>
        <br />
        <div style={{ display: "flex" }}>
          <Button variant='contained' onClick={handlePDF}>Pdf</Button>
          <Button variant='contained' style={{ marginLeft: "50px" }} onClick={handleClose}>Close</Button>
        </div>
        </Box>
      </Modal>
    </div>
    </LoadingOverlay>
  );
}