import React, { useCallback, useEffect, useRef, useState } from 'react'
import { fetchData, fetchDataAll, fetchDataCancelled, fetchDataPending } from '../../services/econsent/econsentServices'
import { Button, Stack } from '@mui/material';
import { channelType, statusType } from '../../services/econsent/types';
import { activeTypes } from './types';
import ExportAction from './export.action';
import { Divider } from '@material-ui/core';
import { MRT_TableInstance } from 'material-react-table';
import ChannelFilter from './channel.filter';
import { CustomDatePicker, CustomDatePickerRef } from '../../components/DatePicker/CustomDatePicker';
import StatusFilter from './status.filter';
import { debounce } from '../../utilities/debounce';

interface Props {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  channel: channelType;
  setMetaData: React.Dispatch<React.SetStateAction<{ [key: string]: string; }[]>>;
  setChannel: React.Dispatch<React.SetStateAction<channelType>>;
  table: MRT_TableInstance<{ [key: string]: string; }>
}

const ToolbarAction = ({
  isLoading,
  setIsLoading,
  channel,
  setMetaData,
  setChannel,
  table
}: Props) => {
  const now = new Date()

  const datePickerRef = useRef<CustomDatePickerRef>(null)
  const [status, setStatus] = useState<string>(activeTypes[0]);

  useEffect(() => {
    if (datePickerRef.current && !datePickerRef.current?.getDate()) {
      datePickerRef.current.setDate(now)
    }
  }, [])

  const handleChangeFilter = useCallback(
    debounce(
      async () => {
        if (status === activeTypes[0]) return

        let date = datePickerRef.current?.getDate();
        setIsLoading(true);
        let data: { [key: string]: string; }[] = []
        switch (status) {
          case activeTypes[1]: data = await fetchDataPending(channel, date); break;
          case activeTypes[2]: data = await fetchData(statusType.PENDING_WITNESS, channel, date); break;
          case activeTypes[3]: data = await fetchData(statusType.COMPLETED, channel, date); break;
          case activeTypes[4]: data = await fetchDataCancelled(channel, date); break;
          case activeTypes[5]: data = await fetchDataAll(channel, date); break;
        }
        setMetaData(data);
        setIsLoading(false);
      }
      , 400
    )
    , [channel, status]
  )

  useEffect(() => {
    handleChangeFilter()
  }, [handleChangeFilter])

  const handleToggleChannel = (newChannel: channelType) => {
    setIsLoading(true);
    setChannel(prev => prev === newChannel ? channelType.NONE : newChannel)
    setStatus(prev => newChannel === channel ? activeTypes[0]: prev === activeTypes[0] ? activeTypes[5]: prev)
    setMetaData([])
    setIsLoading(false);
  }

  const handleToggleStatus = (newStatus: typeof activeTypes[number]) => {
    setStatus(newStatus)
  }

  return (
    <Stack>
      <Stack mb={1}>
        <CustomDatePicker
          ref={datePickerRef}
          setFilterValue={handleChangeFilter}
          label={"Transaction Creation Date"}
          disabled={isLoading}
        />
      </Stack>
      <ChannelFilter
        isLoading={isLoading}
        onToggleChannel={handleToggleChannel}
        channel={channel}
      />
      <StatusFilter
        isLoading={isLoading}
        onToggleStatus={handleToggleStatus}
        status={status}
      />
      <Divider />
      <ExportAction isLoading={isLoading} table={table} />
    </Stack>
  );
}

export default ToolbarAction