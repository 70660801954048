import React, { createContext } from 'react';
import { ContextValue, State } from './type';

export const initialState: State = {
    loading: false,
    cancellationReason: []
};

const MasterContext = createContext<ContextValue>({
    state: initialState,
    dispatch: () => {}
});

export default MasterContext
