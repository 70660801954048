export type onetrustStatusModel = {
  consentItem: consentItemModel[] | null;
}

export type consentItemModel = {
  purposeName: string | null;
  purposeActive: string | null;
  purposeLastUpdatedDate: Date | null;
}

export const UUID_REGEX_PATTERN : RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;