import { useContext, useEffect, useState } from 'react'
import { useIdle } from '../../hooks';
import Swal from 'sweetalert2';
import { logout } from '../../utilities/authentication';
import { Stack } from '@mui/material';
import { Typography } from '@material-ui/core';
import { ConfigContextValue } from '../../contexts/type';
import { ConfigContext } from '../../contexts';

const RemainingTimer = () => {
    const { state: { expiryInSecond, promptExpiryInSecond } } = useContext<ConfigContextValue>(ConfigContext);
    const [remainingTime, setRemainingTime] = useState<number>(0);

    const handleLogOut = () => {
        logout()
    };

    const handleStayLoggedIn = () => {
        reset();
    };

    const handlePrompt = async () => {
        let result = await Swal.fire({
            title: 'Your session is almost expired',
            text: 'What would you like to do?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Stay signed in',
            cancelButtonText: 'Sign out'
        })

        if (result.isConfirmed || result.dismiss === Swal.DismissReason.backdrop) {
            handleStayLoggedIn()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            handleLogOut()
        }
    };

    const { isIdle, getRemainingTime, reset, isPrompted } = useIdle({
        onIdle: handleLogOut,
        idleTime: expiryInSecond,
        onPrompt: handlePrompt,
        promptTime: promptExpiryInSecond
    });

    function updateButtonText(remainingTime: number) {
        if (!isPrompted()) return
        Swal.update({
            confirmButtonText: `Stay signed in : ${millisToHoursAndMinutesAndSeconds(remainingTime)}`,
        });
    }

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (!isIdle) {
            interval = setInterval(() => {
                let remaingTime = getRemainingTime()
                setRemainingTime(remaingTime);
                updateButtonText(remaingTime);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isIdle])

    function millisToHoursAndMinutesAndSeconds(millis: number) {
        var totalSeconds = Math.floor(millis / 1000)

        var hours = '' + Math.floor(totalSeconds / 3600);
        var minutes = '' + (Math.floor(totalSeconds / 60) % 60);
        var seconds = '' + Math.floor(totalSeconds % 60);

        return hours.padStart(2, '0') + ":" + minutes.padStart(2, '0') + ":" + seconds.padStart(2, '0');
    }

    return <></>
    // return (
    //     <Stack direction={'row'} justifyContent={'flex-end'}>
    //         <Typography>Session will expire in: {millisToHoursAndMinutesAndSeconds(remainingTime)}</Typography>
    //     </Stack>
    // )
}

export default RemainingTimer