import './App.css';
import { ConfigContextProvider } from './contexts/config/config.provider';
import { MasterContextProvider } from './contexts/master/master.provider';
import Router from './routers';

function App() {
  return (
    <ConfigContextProvider>
      <MasterContextProvider>
        <div className="App">
          <Router />
        </div>
      </MasterContextProvider>
    </ConfigContextProvider>
  );
}

export default App;
