import React, { createContext } from 'react';
import { ContextValue, State } from './type';

export const initialState: State = {
    expiryInSecond: 3600,
    promptExpiryInSecond: 30
};

const ConfigContext = createContext<ContextValue>({
    state: initialState,
    dispatch: () => {}
});

export default ConfigContext
